import { type ActionFunctionArgs } from "@remix-run/node";

import { Input } from "#app/components/ui/input";
import { Label } from "#app/components/ui/label";
import { Button } from "#app/components/ui/button";
import { MoveRight } from "lucide-react";
import { parseWithZod, getZodConstraint } from "@conform-to/zod";
import { useForm } from "@conform-to/react";
import { z } from "zod";
import Phone from "#app/components/phone";

import { Form, Link, useActionData, useNavigation } from "@remix-run/react";
import LouvelleLogo from "#app/components/logo";
import { INTERESTS, addMemberToGroup } from "#app/lib/mailchimp";
import { saveEmailToDynamo } from "#app/lib/dynamo";

const schema = z.object({
  email: z
    .string({ required_error: "Email address is required" })
    .email("Invalid email address"),
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema });

  if (submission.status !== "success") {
    return submission.reply();
  }

  const { email } = submission.value;

  // save it in mailchimp
  const stage = process.env.stage || "dev";
  if (["production", "uat"].includes(stage)) {
    await addMemberToGroup(email, INTERESTS.User);
  } else if (["dev"].includes(stage)) {
    await addMemberToGroup(email, INTERESTS.Development);
  }
  // save it in dynamo
  await saveEmailToDynamo(email);

  return submission;
}

function EmailForm() {
  const lastResult = useActionData<typeof action>();
  const [form, fields] = useForm({
    lastResult,
    constraint: getZodConstraint(schema),
  });
  const navigation = useNavigation();

  const showForm = !lastResult?.status || lastResult?.status === "error";
  return (
    <Form
      method="POST"
      id={form.id}
      aria-invalid={form.errors ? true : undefined}
      area-describedby={form.errors ? form.errorId : undefined}
    >
      <fieldset disabled={navigation.state === "submitting"}>
        <div className="px-8 w-full flex flex-col md:pl-0 border-dashed border-0 border-blue-500 md:min-h-[140px]">
          {showForm ? (
            <>
              {/* Label and Button */}
              <div aria-hidden={lastResult?.status !== "success"}>
                <div className="flex flex-row border-0 border-pink-500 items-center md:justify-start justify-between">
                  <Label
                    className="font-sans text-base md:text-xl tracking-widest"
                    htmlFor={fields.email.id}
                  >
                    Add your email to the waitlist
                  </Label>
                  <div className="w-4"></div>
                  <Button
                    variant="outline"
                    size="icon"
                    className="border-none bg-transparent"
                  >
                    <MoveRight strokeWidth={1} className="h-8 w-8" />
                  </Button>
                </div>
                {/* Input and Error Label */}
                <Input
                  className="rounded-none border-0 bg-transparent  text-xl"
                  type="email"
                  id={fields.email.id}
                  name={fields.email.name}
                  required={fields.email.required}
                  aria-invalid={fields.email.errors ? true : undefined}
                  aria-describedby={
                    fields.email.errors ? fields.email.errorId : undefined
                  }
                />
                {/* Line  */}
                <div className="h-[2px] border-b-2 border-slate-500"></div>
                <Label
                  id={fields.email.errorId}
                  className="border-0 border-pink-500 p-2 w-full font-sans text-lg text-red-700"
                >
                  {fields.email.errors ? fields.email.errors : <>&nbsp;</>}
                </Label>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center md:items-start border-0 border-orange-500 p-0 animate-in fade-in-0 duration-1000">
              <h1 className="font-sans text-base md:text-xl tracking-wider text-midnight">
                Thank you!
              </h1>
              <p className="px-16 text-center md:w-full md:text-left md:px-0 border-0 border-pink-500 font-sans text-lg md:text-xl tracking-wider leading-normal pt-2">
                Please check your inbox to confirm your subscription.
              </p>
              <Link to="/">
                <p className="text-slate-700 text-base py-4 hover:underline">
                  Add Another Address
                </p>
              </Link>
            </div>
          )}
        </div>
      </fieldset>
    </Form>
  );
}

export default function Index() {
  return (
    <div className="h-full flex w-full md:flex-row flex-col-reverse items-center md:items-start gap-8 md:p-16 pb-0 border-0 border-green-500">
      <div className="w-full md:w-1/2 border-0 border-blue-500">
        <div className="border-0 border-orange-500 flex flex-row md:justify-start justify-center w-full p-4">
          <Phone />
        </div>
      </div>
      <div className="w-full md:h-full md:w-1/2 max-h-[1070px] flex flex-col border-0 border-orange-500 justify-start md:justify-between px-4">
        <div className="flex flex-row justify-center md:justify-start w-full py-16 border-0 border-slate-500">
          <LouvelleLogo width="180px" color="#333333" />
        </div>
        <div className="border-0 border-pink-500 gap-8 flex flex-col items-center md:items-start md:pb-8">
          <p className="font-sans text-base md:text-xl tracking-wider text-midnight ">
            Coming Fall 2024, by invitation.
          </p>
          <p className="text-center md:text-left md:max-w-[600px] border-0 border-blue-500 font-serif text-xl/8 md:text-2xl/8 lg:text-4xl/[50px] uppercase tracking-widest text-midnight">
            A Smarter Way To <br />
            Experience Luxury Apparel.
          </p>
          <div className="w-full border-0 border-orange-500 min-h=[60px]">
            <EmailForm />
          </div>
        </div>
      </div>
    </div>
  );
}
